<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-card>
      <v-subheader>DCP Certificate</v-subheader>
      <v-list-tile>
        <v-icon left>
          mdi-certificate
        </v-icon>
        <span>Dealer Certification Program</span>
        <v-spacer />
        <span v-if="items.entry === 'true'">
          <v-btn
            :href="printCert()"
            target="_blank"
            fab
            small
            color="info"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </span>
        <span v-else-if="items.entry === 'false'">
          <v-btn
            :href="printCertOld()"
            target="_blank"
            fab
            small
            color="info"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </span>
      </v-list-tile>
    </v-card>
  </panel>
</template>

<script>
import { createGetParams, createGetUrl } from '@/rest'

const pageTitle = 'Dealer Certification Program'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Certificates', disabled: false, to: '/certificates',
        },
        {
          text: pageTitle, disabled: true,
        },
      ],
      title: pageTitle,
      items: {},
      item: {},
      loading: false,
    }
  },
  mounted: function () {
    this.getDealerDcp()
  },

  methods: {
    printCert: function () {
      return process.env.NODE_ENV === 'production'
        ? createGetUrl(location.protocol + '//' + location.hostname + '/api/pdf/printCertDealer.php')
        : createGetUrl('http://203.115.197.112/api/pdf/printCertDealer.php')
    },
    printCertOld: function () {
      return process.env.NODE_ENV === 'production'
        ? createGetUrl(location.protocol + '//' + location.hostname + '/api/pdf/printCertOldDealer.php')
        : createGetUrl('http://203.115.197.112/api/pdf/printCertOldDealer.php')
    },
    getDealerDcp () {
      this.loading = true
      const params = createGetParams({})
      this.$rest.get('getDealerDcp.php', params)
        .then((response) => {
          this.items = response.data.items
          this.loading = false
        }).catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>
